<template>
  <mobile-screen :header="true" screen-class="gray-bg fluid clean-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="clean-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_clean'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("clean", "map", "map") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section
      class="map-iframe-wrapper"
      style="display: flex; width: 100%; position: relative; height: 100%"
      v-if="mapUrl"
    >
      <iframe
        ref="mapIframe"
        style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
        :src="mapUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </section>
    <template v-slot:footer>
      <section class="clean-footer-menu icon-foo1">
        <nav class="menu">
          <ul class="menu-items">
            <li class="menu-item central-item icon-foo2">
              <qr-code-scanner-button />
            </li>
          </ul>
        </nav>
      </section>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import { getLang, getSubdomain } from "@/services/http-service";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import qs from "qs";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "CleanResourcesMap",
  mixins: [mapMixin],
  created() {
    if (!this.selectedLevel) {
      this.$router.push({ name: "r_clean" });
    } else {
      this.setMapUrl();
      this.$store.commit("clean/setResourceCleaned", false, { root: true });
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
    }
  },
  computed: {
    ...mapState("clean", ["selectedLevel", "resourceCleaned"])
  },
  data() {
    return {
      mapUrl: null
    };
  },
  methods: {
    setMapUrl() {
      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

      let selectedCleanResource = "";
      if (this.selectedLevel.resource_id != null) {
        selectedCleanResource =
          "&clean_resource=" + this.selectedLevel.resource_id;
      }

      this.mapUrl = `${process.env.VUE_APP_5DMAPS_URL}/view/${
        this.selectedLevel.map_id
      }?api=${this.getMapApi()}&access_token=${localStorage.getItem(
        "atApp"
      )}&language=${getLang()}&mode=clean${subdomainUrl}${selectedCleanResource}`;
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    QrCodeScannerButton
  },
  watch: {
    resourceCleaned(val) {
      if (val) {
        const mapData = qs.stringify({
          resourceCleaned: 1
        });
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "resourceCleaned",
            value: mapData
          },
          "*"
        );
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  }
};
</script>
