export default {
  methods: {
    getMapApi() {
      const envVariables = process.env;
      const { VUE_APP_5DMAPS_URL } = envVariables;

      let api = "local";

      if (VUE_APP_5DMAPS_URL.includes(".dev")) {
        api = "dev";
      } else if (VUE_APP_5DMAPS_URL.includes(".cc")) {
        api = "qa";
      } else if (VUE_APP_5DMAPS_URL.includes(".net")) {
        api = "prod";
      }

      return api;
    }
  }
};
